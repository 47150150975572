import {
  Button,
  Collapse,
  Form,
  Input,
  Modal,
  Select,
  Checkbox,
  Menu,
  Tabs,
  Switch,
  Avatar,
  Row,
  Col,
} from "antd";
import {
  HomeOutlined,
  SettingFilled,
  SmileOutlined,
  SyncOutlined,
  LoadingOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import "antd/dist/antd.less";
import "./styles/font.css";
import React, { Component } from "react";
import { db, storage } from "./firebase";
import Producer from "./Producer";
import firebase from "firebase/app";
import "firebase/auth";
import "./styles/ProducerDropdown.css";
import Logo from './images/VolcanicWines_Logo.png';

const { Search } = Input;

var email = "";
const auth = firebase.auth();
// Check for user status
auth.onAuthStateChanged((user) => {
  email = user.email;
});

var hash = 0;

// const axios = require("axios").default;

// var api_key = "e2a51609aa284de1aa32c76a76610944-9dda225e-99e45d01";

const { Panel } = Collapse;
const { Option } = Select;
const { TabPane } = Tabs;

const openAllPanels = false; // set true to open all panels programmatically for testing purposes

class ProducerDropdown extends Component {
  formRef = React.createRef();
  state = {
    uploaded: false,
    producerInfo: [],
    bookmarks: [], // bookmarks are stored in array, and only stored if starred, and removed if unstarred
    notes: {}, // notes are stored in dict, with wine being key and the notes taken being the value
    isModalVisible: false,
    term: "",
    tab: "All",
  };

  // grab data from firebase
  componentDidMount = () => {
    try {
      let json = require("./volcanic.json");

      console.log(json, "the json obj");
      let data = json.sort((a, b) =>
        a["Table Number: "] - b["Table Number: "]
      ); // sort by table number
      console.log(data)
      this.setState({ producerInfo: data ? data : [] });
      this.setState({ uploaded: true });

      console.log(this.props.user)

    } catch (err) {
      console.error(err);
    }
  };

  componentDidUpdate = async (prevProps, prevState) => {
    if (prevProps !== this.props) {
      console.log('updated Props', this.props)
      if (this.props.user) {
        try {
          const bookmarksRef = db.collection("bookmarks").doc(this.props.user.uid);
          await bookmarksRef.get().then((doc) => {
            console.log(doc.data())
            this.setState({ bookmarks: doc.data().bookmarks });
          });

          const notesRef = db.collection("notes").doc(this.props.user.uid);
          await notesRef.get().then((doc) => {
            console.log(doc.data())
            this.setState({ notes: doc.data().notes });
          });
        } catch (err) {
          console.error(err);
        }
      }
    }
  }

  // to let children update bookmarks state
  updateBookmarks = (bookmarks) => {
    this.setState({ bookmarks: bookmarks });
  };

  // to let children update notes state
  updateNotes = (notes) => {
    this.setState({ notes: notes });
  };

  callback = (key) => {
    // //console.log(key);
  };

  // helper function to create email message
  createMessage = () => {
    // do something
    const message = "body message";
    return message;
  };

  // open form after clicking send email
  openForm = () => {
    this.setState({ isModalVisible: true });
  };

  closeForm = () => {
    this.setState({ isModalVisible: false });
  };

  // hanlding form submission
  submitForm = () => {
    this.setState({ isModalVisible: false });
    this.formRef.current.validateFields().then((values) => {
      this.formRef.current.resetFields();
      this.setState({ formValues: values });
      console.log(this.state.formValues["contact_info"]);
      if (this.state.formValues["contact_info"] == true) {
        var consent = "Yes";
      } else var consent = "No";
      //console.log(this.state.formValues); // values stores the value of the form submission
      //console.log(this.state.notes); // values stores the value of the form submission
      //console.log(this.state.bookmarks); // values stores the value of the form submission
      //console.log("form submitted!");

      var form = new FormData();
      var emailBookmark = JSON.stringify(this.state.bookmarks);
      emailBookmark = emailBookmark.replaceAll("[", "");
      emailBookmark = emailBookmark.replaceAll("]", "");
      emailBookmark = emailBookmark.replaceAll('"', "");
      emailBookmark = emailBookmark.replaceAll(",", ", ");
      var emailNotes = JSON.stringify(this.state.notes);
      emailNotes = emailNotes.replaceAll("{", "");
      emailNotes = emailNotes.replaceAll("}", "");
      emailNotes = emailNotes.replaceAll(",", "\n");
      // add space between colon wine name and notes
      emailNotes = emailNotes.replaceAll(":", ": ");
      console.log(email)

      form.append("from", "Somm.ai <david@somm.ai>");
      form.append("to", email);
      form.append("cc", " volcanicwinesintl@colangelopr.com");
      form.append("subject", "International Volcanic Wines Conference");
      form.append(
        "text",
        "Hello, \n \n" +
        "Thank you for attending the International Volcanic Wines Conference! We hope you enjoyed your time" +
        " tasting the wines and meeting the producers. If you have any questions, please contact us at  " +
        "volcanicwinesintl@colangelopr.com" +
        "\n\nHere are your saved wines: " +
        emailBookmark +
        "\n\n" +
        "Somm.ai X International Volcanic Wines Conference Notes: \n" +
        emailNotes +
        "\n\nWe look forward to seeing you at the next International Volcanic Wines Conference! \n\nCheers,\nThe International Volcanic Wines Conference Team\n\n" +
        "Email: " +
        email +
        "\n" +
        "Company Type: " +
        this.state.formValues["type"] +
        "\n" +
        "Company Name: " +
        this.state.formValues["company_name"] +
        "\n" +
        "Consent to release my contact information: " +
        consent +
        "\n"
      );
      //Original data format in email sent to user, kept in case
      // form.append("text", [

      //     JSON.stringify(this.state.formValues),
      //     JSON.stringify(this.state.bookmarks),
      //     JSON.stringify(this.state.notes),

      // ].join('\n'));

      fetch(
        "https://us-central1-coral-field-215519.cloudfunctions.net/send_mailgun_v2",
        {
          method: "POST",
          mode: "no-cors",
          cache: "no-cache",
          credentials: "same-origin",
          headers: {
            "Content-Type": "form-data",
            Authorization:
              "Basic " +
              btoa(
                "api" +
                ":" +
                "3086978efe1cb5cf42dc9079c71f9ac4-cac494aa-76fe2685"
              ),
          },
          body: form,
        }
      )
        .then(function (response) {
          console.log(response);
        })
        .catch(function (error) {
          console.log(error);
        });
    });
  };

  render() {
    hash = 0;

    const headers = [
      "All",
      "Walk-Around Tasting",
      "Seminar 1",
      "Seminar 2 ",
      "Seminar 3 ",
      "Imported Winery",
    ];

    var wineryDictionary = {};
    for (const x of this.state.producerInfo) {

      if (this.state.tab == "All" || x["Filter: " + this.state.tab]?.trim() == 'x' || (this.state && typeof this.state.tab === 'string' && this.state.tab.split(" : ").length === 2 && x[this.state.tab.split(" : ")[0]] === this.state.tab.split(" : ")[1])) {

        let currentWinery = x["Producer"];
        let tableNo = x["Table Number: "];
        if (tableNo in wineryDictionary) {
          if (currentWinery in wineryDictionary[tableNo]) {
            wineryDictionary[tableNo][currentWinery].push(x);
          }
          else {
            wineryDictionary[tableNo][currentWinery] = [x];
          }
        } else {
          wineryDictionary[tableNo] = {};
          wineryDictionary[tableNo][currentWinery] = [x];
        }
      }
    }
    // wineryDictionary.sort();
    console.log(wineryDictionary);
    let tableHash = -1;

    if (window.location.hash) {
      hash = window.location.hash.substring(1); //Puts hash in variable, and removes the # character
      console.log("Current hash: " + hash);
      // hash found
      Object.entries(wineryDictionary).map((producer, index) => {
        if (true) {
          console.log(producer);
          let winery = producer[0];
          let wines = producer[1];
          for (const wine of wines) {
            console.log(wine);
            if (wine["Item No."] == hash) {
              // console.log("Table Found! at N: " + (index + 1));
              tableHash = index + 1;
            }
          }
        }
      });
    } else {
      // No hash found
    }



    return this.state.uploaded && this.state.producerInfo ? (
      <>
        <div>
          <div>
            <center>
              <img src={Logo} width="250em" />
              <br></br>
              <br></br>
              <br></br>
              <a href="https://www.volcanicwinesinternational.com/" target="_blank">https://www.volcanicwinesinternational.com/</a>
            </center>
          </div>
          <br></br>
          <h4>How-to Guide</h4>
          <div>
            Thank you for attending the International Volcanic Wines Conference. Here is how to use
            our application:<br></br>
            1. Navigate to the wine at your table number in the list below <br></br>
            2. Write down your personal wine-tasting notes and click "Save"
            <br></br>
            3. Bookmark your favorite wines with the star icon<br></br>
            4. Simply click "Email me my saved wines and notes" to receive your
            personalized list of wines from today's event!<br></br>
            <br></br>
          </div>

          <Button
            onClick={async () => {
              this.openForm();
            }}
          >
            Email me my saved wines and notes
          </Button>
          <Modal
            title="Email me my saved wines and notes"
            visible={this.state.isModalVisible}
            onOk={this.submitForm}
            onCancel={this.closeForm}
            okText="Submit"
          >
            <Form layout="vertical" name="form_in_modal" ref={this.formRef}>
              <Form.Item name="type" label="Type">
                <Select placeholder="Choose an option" allowClear>
                  <Option value="distributor">Distributor/Importer</Option>
                  <Option value="press">Press</Option>
                  <Option value="restaurant">Restaurant/Bar</Option>
                  <Option value="store">Retail Store</Option>
                  <Option value="consultant">Wine Consultant</Option>
                  <Option value="school">Wine School/Institute</Option>
                  <Option value="winery">Winery</Option>
                  <Option value="other">Other</Option>
                </Select>
              </Form.Item>
              <Form.Item name="company_name" label="Company Name">
                <Input type="textarea" />
              </Form.Item>
              <Form.Item
                name="contact_info"
                valuePropName="checked"
                initialValue={true}
              >
                <Checkbox>
                  I agree to provide my contact information to the wineries
                </Checkbox>
              </Form.Item>
            </Form>
          </Modal>
          {/* don't need update file button, keeping code just in case */}
          {false && (
            <div style={{ float: "right" }}>
              <p>
                Update file: &ensp;
                <input
                  type="file"
                  onChange={(e) => {
                    const file = e.target.files[0];
                    //console.log(file);
                    this.readFile(file);
                    this.setState({ uploaded: true });
                  }}
                />
              </p>
            </div>
          )}
        </div>

        <Menu onClick={(e) => this.setState({ tab: e.key })} mode="horizontal">
          {headers.map((header, index) => {
            return (
              <Menu.Item key={header} style={{ fontSize: 12, padding: 0 }}>
                {header}
              </Menu.Item>
            )
          })}
          <Menu.SubMenu key="Country" title="Country">
            {["Greece",
              "United States",
              "Cyprus",
              "Portugal",
              "Austria",
              "Hungary",
              "Italy",].map(x => <Menu.Item key={` Country : ${x}`}>{x}</Menu.Item>)}
          </Menu.SubMenu>
          <Menu.SubMenu key="Region" title="Region">
            {[
              "Santorini",
              "Lake County",
              "Cyprus",
              "Azores",
              "Vulkanland Steiermark",
              "Tokaj-Hegyalja",
              "Etna",
              "Soave",
              "Lipari",
              "Etna, Pantelleria",
              "Lazio",
              "Sannio",
              "Vesuvio",
              "Roccamonfina, Campania",
              "Vulture",
              "Pitigliano",
              "Colli Euganei",
              "Sonoma",
            ].map(x => <Menu.Item key={`Volcanic Region : ${x}`}>{x}</Menu.Item>)}
          </Menu.SubMenu>
        </Menu>


        {wineryDictionary && (
          <div>
            <h4>
              <center>{this.state.tab}</center>
            </h4>
          </div>
        )}
        {wineryDictionary && (
          <Collapse onChange={this.callback} defaultActiveKey={openAllPanels ? Array.from(Array(100).keys()) : tableHash}>
            {Object.entries(wineryDictionary).map((tableNo, index) => {
              var disp = true;
              if (disp) {
                // console.log(tableNo)
                let wineries = tableNo[1];
                return Object.entries(wineries).map((producer, prodIndex) => {
                  let winery = producer[0]
                  let wines = producer[1]
                  let sampleWine = wines[0];
                  if (sampleWine['Wine Estate'] != null) {
                    var appellation = " - " + sampleWine["Wine Estate"]
                  }
                  else { var appellation = "" }

                  if (sampleWine["Importer"] != null) {
                    var country = ", " + sampleWine["Importer"]
                  }
                  else { var country = "" }

                  if (isNaN(sampleWine["Table Number: "])) {
                    var tableNum = sampleWine["Table Number: "]
                  }
                  else { var tableNum = "Table Number " + sampleWine["Table Number: "]; }

                  return (
                    <Panel
                      id={parseInt(index.toString() + prodIndex.toString())}
                      key={parseInt(index.toString() + prodIndex.toString())}
                      header={
                        tableNum +
                        " - " +
                        appellation
                      }
                      activeKey={hash}
                    >
                      {/* <Collapse onChange={this.callback} defaultActiveKey={hash}> */}
                      {wines.map((wine) => {
                        return (
                          <>
                            {wine["Description"] != null &&
                              <div>
                                <strong>Description: </strong>
                                {wine["Description"]}
                                <br />
                              </div>
                            }
                            {wine["Sustainable Actions"] != null &&
                              <div>
                                <br />
                                <strong>Sustainable Actions: </strong>
                                {wine["Sustainable Actions"]}
                              </div>
                            }
                            <Producer
                              prodInfo={wine}
                              user={this.props.user}
                              bookmarks={this.state.bookmarks}
                              updateBookmarks={this.updateBookmarks}
                              notes={this.state.notes}
                              updateNotes={this.updateNotes}
                            />
                          </>
                        );

                      })}
                      {/* </Collapse> */}
                    </Panel>
                  );
                })

              }

            })}
          </Collapse>
        )}


      </>
    ) : null;
  }
}

export default ProducerDropdown;
