import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useHistory } from "react-router";
import "./styles/Dashboard.css";
import { auth, db, logout } from "./firebase";
import ProducerDropdown from "./ProducerDropdown";

function Dashboard() {
  const [user, loading, error] = useAuthState(auth);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const history = useHistory();

  const fetchData = async () => {
    try {
      const query = await db
        .collection("users")
        .where("uid", "==", user?.uid)
        .get();
      const data = await query.docs[0].data();
      setEmail(data.email);
      setName(data.name);

      const notes_query = await db
        .collection("notes")
        .where("uid", "==", user.uid)
        .get();
      if (notes_query.docs.length === 0) {
        await db.collection("notes").doc(user.uid).set({
          uid: user.uid,
          notes: {},
        });
      }
      const bookmark_query = await db
        .collection("bookmarks")
        .where("uid", "==", user.uid)
        .get();
      if (bookmark_query.docs.length === 0) {
        await db.collection("bookmarks").doc(user.uid).set({
          uid: user.uid,
          bookmarks: [],
        });
      }
    } catch (err) {
      console.error(err);
    //   alert("An error occured while fetching user data");
    }
  };

  useEffect(() => {
    if (loading) return;
    if (!user) return history.replace("/");

    fetchData();
  }, [user, loading]);

  return (
    <div style={{justifyContent: "center"}}> 
        <div className="dashboard">
            {/* <div>Logged in as</div>
            <div>{name}</div>
            <div>{user?.email}</div> */}
            <img src="/somm.png"/>
            <a className="dashboard__btn" onClick={logout}>
                Logout
            </a>
        </div>
        <div className="producer" style={{padding: "2vw 10vw"}}>
            <ProducerDropdown email={email} user={user}/>
        </div>
        
    </div>
    
  );
}

export default Dashboard;