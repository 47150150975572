import logo from './logo.svg';
import './styles/App.less';
import React, {Component} from "react"; 
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Login from "./Login";
import Register from "./Register";
import Reset from "./Reset";
import Dashboard from "./Dashboard";

class App extends Component {
  render() {
    console.log("test")
    return (
      <div className="app">
        <Router>
          <Switch>
            <Route exact path="/" component={Login} />
            {/* <Route exact path="/register" component={Register} />
            <Route exact path="/reset" component={Reset} /> */}
            <Route exact path="/dashboard" component={Dashboard} />
          </Switch>
        </Router>
      </div>
    );
  }
  
}

export default App;
