// for EmailJS service
// link for docs: https://www.emailjs.com/docs/sdk/send/
const SERVICE_ID = "service_pqk1vxq";
const TEMPLATE_ID = "template_2xa2oxh";
const USER_ID = "user_c1WEPYPeZ3yAUKtfFRUYp"

// Your web app's Firebase configuration
const FIREBASE_CONFIG = {
  apiKey: "AIzaSyBUzXXcWz4yb-X9DAM7UQgjm8O_5hc2t3A",
  authDomain: "vol1-cdbde.firebaseapp.com",
  projectId: "vol1-cdbde",
  storageBucket: "vol1-cdbde.appspot.com",
  messagingSenderId: "308403707531",
  appId: "1:308403707531:web:c1c50af5f93eaf17703e8e",
  measurementId: "G-Z0HQWW8G79"
};


// will need to change redirect url for production
const ACTION_CODE_SETTINGS = {
  // Your redirect URL
  url: 'https://colangelo.somm.ai', 
  handleCodeInApp: true,
};

export {SERVICE_ID, TEMPLATE_ID, USER_ID, FIREBASE_CONFIG, ACTION_CODE_SETTINGS};