import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { auth, signInWithEmailOnly, signInWithEmailAndPassword, registerWithEmailAndPassword, signInWithGoogle, confirmSignIn } from "./firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import "./styles/Login.css";

const defaultName = "user";
const defaultPassword = "password";

function Login() {
  const [email, setEmail] = useState(
    window.localStorage.getItem("emailForSignIn") || ""
  );
  // const [password, setPassword] = useState("");
  const [user, loading, error] = useAuthState(auth);
  const history = useHistory();

  useEffect(() => {
    
    confirmSignIn(window.location.href);

    if (loading) {
      // maybe trigger a loading screen
      return;
    }
    if (user) history.replace("/dashboard");
  }, [user, loading]);

  return (
    <div className="login">
      <img className="logo" src="/somm.png"/>
      <div className="login__container">
        <input
          type="text"
          className="login__textBox"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter your e-mail"
        />
        {/* <input
          type="password"
          className="login__textBox"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
        /> */}
        <button
          className="login__btn"
          onClick={() => registerWithEmailAndPassword(defaultName, email, defaultPassword)}
        >
          Sign-up to Receive Your Notes
        </button>
        <button className="login__btn login__google" onClick={signInWithGoogle}>
          Sign up to Receive Your Notes, Here with Google
        </button>
        {/* <div>
          <Link to="/reset">Forgot Password</Link>
        </div>
        <div>
          Don't have an account? <Link to="/register">Register</Link> now.
        </div> */}
      </div>
    </div>
  );
}

export default Login;