import React, { Component } from "react";
import { Collapse } from 'antd';
import { StarOutlined, StarFilled } from '@ant-design/icons';
import 'antd/dist/antd.less';
import { db } from "./firebase";
import { fieldset, Button, Form, FormControl } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';

const { Panel } = Collapse;

class Producer extends Component {
    state = {
        edit_1: false,
        edit_2: false,
        edit_3: false,
    }

    // componentDidMount = async () => {
    //     // load bookmarks + wines
    //     // const bookmarksRef = await db
    //     //     .collection("bookmarks")
    //     //     .doc(this.props.user?.uid);
    //     // bookmarksRef.get().then(doc => {
    //     //     this.setState({bookmarks: doc.data().bookmarks});
    //     // })

    //     // const notesRef = await db
    //     //     .collection("notes")
    //     //     .doc(this.props.user?.uid);
    //     // notesRef.get().then(doc => {
    //     //     this.setState({notes: doc.data().notes})
    //     // })
    // }

    changeBookmark = async (header) => {
        let bookmarks = this.props.bookmarks;
        bookmarks.includes(header) ? bookmarks.splice(bookmarks.indexOf(header), 1) : bookmarks.push(header);
        this.props.updateBookmarks(bookmarks);

        console.log("updating bookmark")
        // update firebase
        await db.collection("bookmarks").doc(this.props.user.uid).set({
            bookmarks: bookmarks
        }, { merge: true }).then(() => console.log("bookmarks updated!"))
    }

    saveNotes = async (e, wine, number) => {
        e.preventDefault();

        // add notes to firebase
        const newNote = number === 1 ? e.target.notes1.value : (number === 2 ? e.target.notes2.value : e.target.notes3.value)
        let notes = this.props.notes;
        if (newNote) { // if notes is not an empty string, update it in map
            notes[wine] = newNote;
        }
        else { // if empty string (note), remove so that it is no longer stored in firebase
            delete notes[wine];
            console.log("notes cleared!")
        }
        this.props.updateNotes(notes);

        // update firebase
        await db.collection("notes").doc(this.props.user.uid).set({
            notes: notes
        }, { merge: true }).then(() => console.log("notes saved!"))
    }

    render() {
        const { edit_1, edit_2, edit_3 } = this.state;
        const { prodInfo, notes } = this.props;
        // if (prodInfo["2CS"] && prodInfo["3CS"]!=null) {var cs2 = ">> " + prodInfo["2CS"]}
        // else var cs2 = ""
        // if (prodInfo["3CS"] && prodInfo["3CS"]!=null) {var cs3 = ">> " + prodInfo["3CS"]}
        // else var cs3 = ""
        // if (prodInfo["4CS"] && prodInfo["4CS"]!=null) {var cs4 = ">> " + prodInfo["4CS"]}
        // else var cs4 = ""
        // if (prodInfo["5CS"] && prodInfo["5CS"]!=null) {var cs5 = ">> " + prodInfo["5CS"]}
        // else var cs5 = ""
        // if (prodInfo["10CS"] && prodInfo["10CS"]!=null) {var cs10 = ">> " + prodInfo["10CS"]}
        // else var cs10 = ""
        if (prodInfo["Other Price/QTY"] && prodInfo["Other Price/QTY"] != null) { var otherPrice = ">> " + prodInfo["Other Price/QTY"] }
        else var otherPrice = ""
        // if (prodInfo["FL"] && prodInfo["FL"]!=null) {var fl = " " + prodInfo["FL"]}
        // else fl = ""
        // if (prodInfo["BTL"] && prodInfo["BTL"]!=null) {var btl = " (" + prodInfo["BTL"] + "/btl) "}
        // else btl = ""
        // if (prodInfo["FL BTL"] && prodInfo["FL BTL"]!=null) {var flBtl = " " + prodInfo["FL BTL"] + "/btl "}
        // else flBtl = ""
        // if (prodInfo["12 BTL MIXED"] && prodInfo["12 BTL MIXED"]!=null) {var btl12Mixed = ">> " + prodInfo["12 BTL MIXED"] + "/12 btl mixed"}
        // else var btl12Mixed = ""
        // if (prodInfo["12 BTL"] && prodInfo["12 BTL"]!=null) {var btl12 = ">> " + prodInfo["12 BTL"] + "/12 btl"}
        // else var btl12 = ""
        // if (prodInfo["24 BTL"] && prodInfo["24 BTL"]!=null) {var btl24 = ">> " + prodInfo["24 BTL"] + "/24 btl"}
        // else var btl24 = ""

        // if (prodInfo["FILTER: Spirits"] == "x") {var head = prodInfo["Wine"]}
        // else {var head = prodInfo["Wine"] + " " + prodInfo["Vintage"]}
        var head = ""
        { prodInfo["Vintage"] !== (null || undefined) ? head = prodInfo["Wine"] + " " + prodInfo["Vintage"] : head = prodInfo["Wine"] }

        return (
            <>
                <Collapse defaultActiveKey={window.location.hash.substring(1)}

                >
                    {prodInfo["Wine"] ?
                        <Panel
                            id={prodInfo["Wine"]}
                            header={head}
                            key={prodInfo["Wine"]}
                            extra={
                                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                <a onClick={(event) => {
                                    event.stopPropagation();
                                    this.changeBookmark(prodInfo["Wine"]);
                                }}>
                                    {this.props.bookmarks.includes(prodInfo["Wine"]) ? <StarFilled /> : <StarOutlined />}
                                </a>
                            }
                        >
                            {
                                ["Winery Address", "Country", "Volcanic Region", "Contact", "Email", "Importer", "Importer Contact Name", 'Importer Contact Email']
                                    .map(x => (prodInfo[x] ?
                                        <div>
                                            <strong>{x}: </strong>
                                            {prodInfo[x]}
                                        </div>
                                        : null))
                            }
                            <br />

                            {prodInfo["Website"] ?
                                <div>
                                    <strong>Website: </strong>
                                    <a href={prodInfo["Website"]} target="_blank">{prodInfo["Website"]}</a>
                                </div>
                                : ""}

                            <br />


                            <div>
                                <strong>Grapes: </strong>
                                {prodInfo["Grapes"]}
                            </div>

                            {prodInfo["ABV"] != null ?
                                <div>
                                    <strong>ABV: </strong>
                                    {prodInfo["ABV"]}
                                </div>
                                : ""}
                            {prodInfo["Social Reference Pricing"] ?
                                <div>
                                    <strong>Social Reference Pricing: </strong>
                                    {prodInfo["Social Reference Pricing"]}
                                </div>
                                : ""}

                            {prodInfo["Link"] != null ?
                                <div>
                                    <strong>More Info: </strong>
                                    <a href={prodInfo["Link"]} target="_blank">{prodInfo["Link"]}</a>
                                </div> : ""}

                            <br>
                            </br>
                            {/* {prodInfo["FILTER: Spirits"] == "x"?
                      prodInfo["Case"] + flBtl + btl12Mixed + btl12 + btl24: prodInfo["Case"] + fl + btl + cs2 + cs3 + cs4 + cs5 + cs10 + otherPrice} */}

                            <div>
                                <Form onSubmit={(e) => { this.saveNotes(e, prodInfo["Wine"], 1); this.setState({ edit_1: !this.state.edit_1 }); }}>
                                    <Form.Group>
                                        <Form.Label>Notes:</Form.Label>
                                        <FormControl as="textarea" type="text" rows={5} defaultValue={prodInfo["Wine"] in notes ? notes[prodInfo["Wine"]] : ""} name="notes1" onClick={() => this.setState({ edit_1: true })} />
                                    </Form.Group>
                                    <fieldset disabled={edit_1 ? false : true}>
                                        {edit_1 && <div style={{ paddingTop: "5px" }}>
                                            <Button size="sm" variant="outline-primary" type="submit">
                                                Save
                                            </Button>
                                        </div>}
                                    </fieldset>
                                </Form>
                            </div>
                        </Panel> : null}
                </Collapse>

                {/* {prodInfo["Wine #2"].trim()  ? 
                    <Panel 
                        header={"Wine #2 - " + prodInfo["Wine #2"]} 
                        key={2}
                        extra={
                            // eslint-disable-next-line jsx-a11y/anchor-is-valid
                            <a onClick={(event) => {
                                event.stopPropagation();
                                this.changeBookmark(prodInfo["Wine #2"]);
                            }}>
                                {this.props.bookmarks.includes(prodInfo["Wine #2"]) ? <StarFilled /> : <StarOutlined />}
                            </a>}
                    >
                        <div>  
                            <strong>Blend: </strong>{prodInfo["Blend #2"]}
                        </div>
                        <div>  
                            <strong>No. bottles produced: </strong>{prodInfo["N. of bottles produced #2 "]}
                        </div>
                        <div>  
                            <strong>Organic wine: </strong>{prodInfo["Organic wine #2"]}
                        </div>
                        <div>  
                            <strong>Single vineyard: </strong>{prodInfo["Single vineyard #2"]}
                        </div>
                        <div>
                            <Form onSubmit={(e) => {this.saveNotes(e, prodInfo["Wine #2"], 2); this.setState({edit_2: !this.state.edit_2}); }}>
                                <Form.Group>
                                    <Form.Label>Notes:</Form.Label>
                                    <FormControl as="textarea" rows={5} defaultValue={prodInfo["Wine #2"] in notes ? notes[prodInfo["Wine #2"]] : null} name="notes2" onClick={() => this.setState({ edit_2: true })}/>
                                </Form.Group>
                                <fieldset disabled={edit_2 ? false : true}>

                                    {edit_2 && <div style={{paddingTop: "5px"}}>
                                        <Button size="sm"  variant="outline-primary" type="submit">
                                            Save
                                        </Button>
                                    </div>}
                                </fieldset>
                            </Form>
                        </div>
                    </Panel>: null }

                {prodInfo["Wine #3"].trim() ? 
                    <Panel 
                        header={"Wine #3 - " + prodInfo["Wine #3"]} 
                        key={3}
                        extra={
                            // eslint-disable-next-line jsx-a11y/anchor-is-valid
                            <a onClick={(event) => {
                                event.stopPropagation();
                                this.changeBookmark(prodInfo["Wine #3"]);
                            }}>
                                {this.props.bookmarks.includes(prodInfo["Wine #3"]) ? <StarFilled /> : <StarOutlined />}
                            </a>}
                    >
                        <div>  
                            <strong>Blend: </strong>{prodInfo["Blend #3"]}
                        </div>
                        <div>  
                            <strong>No. bottles produced: </strong>{prodInfo["N. of bottles produced #3 "]}
                        </div>
                        <div>  
                            <strong>Organic wine: </strong>{prodInfo["Organic wine #3"]}
                        </div>
                        <div>  
                            <strong>Single vineyard: </strong>{prodInfo["Single vineyard #3"]}
                        </div>
                        <div>
                            <Form onSubmit={(e) => {this.saveNotes(e, prodInfo["Wine #3"], 3); this.setState({edit_3: !this.state.edit_3}); }}>
                                <Form.Group>
                                    <Form.Label>Notes:</Form.Label>
                                    <FormControl as="textarea" rows={5} defaultValue={prodInfo["Wine #3"] in notes ? notes[prodInfo["Wine #3"]] : null} name="notes3" onClick={() => this.setState({ edit_3: true })}/>
                                </Form.Group>
                                <fieldset disabled={edit_3 ? false : true}>
                                    {edit_3 && <div style={{paddingTop: "5px"}}>
                                        <Button size="sm"  variant="outline-primary" type="submit">
                                            Save
                                        </Button>
                                    </div>}
                                </fieldset>
                            </Form>
                        </div>
                    </Panel>: null } */}
            </>
        )
    }
}

export default Producer;